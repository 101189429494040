import React from "react"
import styles from './songTitle.module.scss'


const SongTitle = ( {index, name_eng, name_chi, dataImageTag, currArtistId} ) => {

    let songImg;  

    if (index === 0) {
        songImg = (
            <img
                data-clickedon="song"
                data-img= {dataImageTag}  
                className={styles.title_image_nian} src="../nian_black.svg" alt="A Pure Person: 單純的人" data-songindex={index}/>
        )
    } else {
        songImg = (
            <img
            data-clickedon="song"
            data-img= {dataImageTag}  
            className={styles.title_image_pure} src="../pure_handwriting.svg" alt="A Pure Person: 單純的人" data-songindex={index}/>
        )
    }

    let indexStyles = []; 

    if (parseInt(currArtistId) === parseInt(index)) {
        indexStyles = [ styles.index, styles.activeIndex ]
    } else {
        indexStyles= [styles.index]
    }

    return (
        <div className={styles.song_item_wrapper}>
                <div 
                    data-clickedon="song"
                    tabIndex="0"
                    data-img= {dataImageTag} 
                    className={[styles.title_wrapper, "change-image"].join(' ')} 
                    data-songindex={index}>
                    <div className={indexStyles.join(' ')} 
                        data-clickedon="song"
                        data-img= {dataImageTag} 
                        data-songindex={index}>{ `0${index+1}` } </div>
                         {songImg}
                    <div 
                        data-clickedon="song"
                        data-img= {dataImageTag} 
                        className={styles.title_text} data-songindex={index}> A Pure Person</div>
                </div>
            
                <div
                    aria-label={`${name_chi} / ${name_eng}`}
                    role="button" 
                    tabIndex="0"
                    data-clickedon ="artist"
                    className={[styles.artist_wrapper, "change-image"].join(' ')} 
                    data-img= {dataImageTag} 
                    data-songindex={index}>
                    <div 
                    data-clickedon ="artist"
                    data-img= {dataImageTag} 
                    className={styles.artist_chi} data-songindex={index}> { name_chi }</div>
                    <div 
                        data-clickedon ="artist"
                        data-img= {dataImageTag} 
                        className={styles.artist_eng} data-songindex={index}> { name_eng } </div>
                </div>  
        </div>
    )
};

export default SongTitle; 

