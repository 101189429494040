import React from "react"
import styles from './overlay.module.scss'
import { MDXRenderer } from "gatsby-plugin-mdx"

const Overlay = ({content, showOverlay, clicked}) => {
 
    let bioClasses = []; 

    const focusClose = () => {
        const closeButton =  document.getElementById("profileClose")
        // document.getElementById("actionMenu").tabIndex="-1"
        closeButton.tabIndex="0"
        closeButton.focus()
    }

    const keyClick = (e) =>{
        if (e.keyCode === 13 ) {
            // document.getElementsByTagName('button')[0].focus()
            clicked()
        }
    }

    if (showOverlay){
        bioClasses = [styles.showBio, styles.overlay_wrapper];
        focusClose()
    } else {
        bioClasses = [styles.overlay_wrapper]
    }

    return (

        <div className={bioClasses.join(' ')}>
            <button onClick={clicked} onKeyUp={keyClick} id="profileClose" className={styles.overlay_x} aria-label="Close Artist Profile" tabIndex="0" > &#8592;</button>
            <div className={styles.overlay_content}>
                <MDXRenderer>{content}</MDXRenderer>
            </div>
        </div>
    )
}

export default Overlay; 