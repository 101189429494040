import React from "react"
import styles from './introOverlay.module.scss'

const IntroOverlay = ({showIntro, clicked}) => {

    let introClasses = [];

    const focusClose = () => {
        const closeButton =  document.getElementById("introClose")
        document.getElementById("actionMenu").tabIndex="-1"
        closeButton.tabIndex="0"
        closeButton.focus()
    }

    if (showIntro) {
        introClasses = [ styles.showIntro, styles.overlay_wrapper ]
        focusClose()
    } else { 
        introClasses = [ styles.overlay_wrapper ]
    }

    const keyClick = (e) =>{
        if (e.keyCode === 13 ) {
            clicked()
        }
    }

    return (
        <div className={introClasses.join(' ')}>
            <button id="introClose" onClick={clicked} onKeyUp={keyClick} className={styles.overlay_x} tabIndex="0" aria-label="Close Introduction"> &#8592;</button>
            <section title="About A Pure Person Introduction" className = {styles.section_container}>
                <div className={styles.section_header}> 
                    <h4>Introduction</h4>
                </div>
                <div className = {styles.intro_content}>
                    <div className={styles.intro_chi}>
                        <p>在侯孝賢導演2001年的電影作品《千禧曼波》中，最具指標性的第一幕，為女主角Vicky（舒淇）漫步於天橋長廊上，正當她左顧右盼，向後向前又再向後看時，髮絲散落在臉龐上，此時，林強的〈單純的人〉開始播放⋯⋯雖然Vicky的生活破碎，但在那一個時刻，她似乎領悟到了什麼，沒有痛苦，沒有過去、現在和未來，她只存在於這三種狀態合一的單純境界。 整張專輯在探討和瞭解，成為一位單純的人意味著什麼，並藉由新的旋律和哲學思維，來重新轉譯林強原版的〈單純的人〉這首歌，而專輯中也包含了一首林強的新作〈念〉。全新詮釋後的《單純的人》專輯，橫跨了過去、現在和未來，從單純的心出發，也請用單純的心來感受。</p>
                    </div>
                    <div className = {styles.intro_eng}> <p>In the iconic opening scene of Hou Hsiao Hsien’s Millennium Mambo (2001), the actress Shu Qi glides through a tunnel. Her hair slides across her face as she looks left, right, back, forward, then back again as Lim Giong’s “A Pure Person” begins to play. Her life is broken, but in that moment, she escapes  -  there is no pain. There is no sense of past, present, or future  -  she exists as a pure person within all three. <br/><br/>
This album seeks to understand what it means to be a pure person. Through new melodic and philosophical interpretations of Lim Giong’s original track as well as one new track from Lim, A Pure Person contains the past, present, and future. Created with the purest of intentions, please enjoy with the purest of intentions.</p></div>
                </div>
            </section>
        </div>
    )
}

export default IntroOverlay; 