import React from "react"
import styles from './aboutOverlay.module.scss'

const AboutOverlay = ({showAbout, clicked}) => {

    let aboutClasses = [];

    const credits = [
        {
            title_eng: "Executive Music Producer",
            title_chi: "音樂製作人",
            name_eng: "Lim Giong", 
            name_chi: "林強"
        }, 
        {
            title_eng: "Record Producer/A&R",
            title_chi: "專輯製作人",
            name_eng: "Angela Lin",
            name_chi:  "林時群",
        }, 
        {
            title_eng: "Production Manager",
            title_chi: "執行製作",
            name_eng:  "Yun Nien",
            name_chi: "粘隶芸"
        }, 
        {
            title_eng: "Mastering",
            title_chi: "母帶後期製作",
            name_eng:  "Ben Wang ",
            name_chi: "王秉皇"
        }, 
        {
            title_eng: "Website",
            title_chi: "網站設計與開發",
            name_eng: "Grace Yang",
            name_chi: "楊慈安",
        },
        {
            title_eng:  "Special thanks to",
            title_chi:  "特別感謝:",
            name_eng: "Lujaichi＋Li He＋Point" ,
            name_chi: "盧家齊、李和 、點"
        }
       
    ];

    var footerContent = [
        {
            chi: "本作品獲文化部影視及流行音樂產業局 108年 補助案補助。", 
            eng: "Sponsored by The Bureau of Audiovisual and Music Industry Development (BAMID), MOC."
        }, 
        {
            chi: "給我的母親：我沒有放棄。我會永遠努力成為一個單純的人。", 
            eng: "For my mother: I haven’t given up. I will always strive to be a pure person. "
        }, 
        {
            chi: "本專輯的所有收益將捐致需幫助之慈善機構。", 
            eng: "All proceeds from this album will go to a charity in need."
        }
    ]
        
    if (showAbout) {
        aboutClasses = [ styles.showAbout, styles.overlay_wrapper ]
    } else { 
        aboutClasses = [ styles.overlay_wrapper ]
    }

    const keyClick = (e) =>{
        if (e.keyCode === 13 ) {
            document.getElementsByTagName('button')[0].focus()
            clicked()
        }
    }

    return (
        <div className={aboutClasses.join(' ')}>
            <div onClick={clicked} onKeyUp= {keyClick} role = "button" id="aboutClose" className={styles.overlay_x} aria-label="Close About Information" tabIndex="0"> &#8592;</div>

            <section title="A Pure Person lyrics" className = {styles.section_container}>
                <div className={styles.section_header}> 
                    <h4>Lyrics</h4>
                </div>
                <div className = {styles.lyrics_content}>
                    <p className={styles.lyrics_chi}>
                    善良， 平凡，快樂｜單純的人｜每次一看到｜平凡善良的人｜心裡就感覺｜平靜快樂｜啊哈｜軟棉棉的和風 ｜軟棉棉的和風｜軟棉棉的和風｜軟棉棉的和風｜單純的人｜每次一看到｜平凡善良的人｜心裡就感覺｜平靜快樂｜啊哈啊｜生活才有一些希望   軟棉棉的和風    單純的人｜生活才有一些希望   軟棉棉的和風    單純的人｜生活才有一些希望 單純的人｜生活才有一些希望
</p>
                    <div className={styles.lyrics_credits_chi}>
                        <img className={styles.writingImg} src="../pure_handwriting.svg" alt="" />
                        <p>詞曲原著：林強</p>
                    </div>

                    <p className = {styles.lyrics_eng}> Kindness, ordinary, happy. A Pure Person. Every time I see it: ordinary, kind, person. I feel it inside my heart. Calm and happy. Ahh, a breeze soft like cotton, a breeze soft like cotton, a breeze soft like cotton, a breeze soft like cotton. A Pure Person.  Every time I see it: ordinary, kind, person. I feel it inside my heart. Calm and happy. Ahh, life has hope: a breeze soft like cotton. A Pure Person. Life has hope: a breeze soft like cotton. A Pure Person. Life has hope. A Pure Person. Life has hope.   </p>
                    <p className={styles.lyrics_credits_eng}>
                        <span>A Pure Person</span><br/>
                        Original words and melody by Lim Giong
                    </p>
                </div>
            </section>

            <hr></hr>

            <section className = {styles.section_container}>
                <div className={styles.section_header}> 
                    <h4>Credits</h4>
                </div>
                <div className = {styles.credits_content}>
                    {
                        credits.map( (credit, index) => {
                         
                                return(
                                    <p key={`${credit.name_eng}`} className={styles.credits_container}>
                                        <span className={styles.credits_title}>
                                        {credit.title_eng}<br/>{credit.title_chi} 
                                        </span><br/>
                                        <span className={styles.credits_name}> 
                                            <span className={styles.name_eng}>{credit.name_eng} </span>{credit.name_chi}
                                        </span>
                                    </p>
                                );
                        })
                    }
                    
                </div>
            </section>

            <hr></hr>

            <section className = {styles.section_container}>
                <div className={[styles.section_header, styles.footer_header].join(' ')}> 
                    <h4>Pure001</h4>
                </div>    
                <div className = {styles.footer_content}>
                   {
                       footerContent.map((content) => {
                           return (
                               <div className={styles.footer_card}>
                                   <p> {content.chi}</p> 
                                   <p> {content.eng}</p>
                               </div>
                           )
                       })
                   }
                </div>
            </section>
        </div>



    )
}

export default AboutOverlay; 