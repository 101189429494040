import React, {useState, useEffect, useRef} from "react"
import { graphql, useStaticQuery } from "gatsby"
import styles from "./index.module.scss"
import "../styles.scss" 
// import { Curtains } from "curtainsjs";
import SongTitle from "../components/songTitle"
import ActionMenu from "../components/actionMenu"
import Overlay from "../components/overlay"
import AboutOverlay from "../components/aboutOverlay"
import SEO from "../components/seo"
import IntroOverlay from "../components/introOverlay"

import SongCanvas from "../components/songCanvas"

  const IndexPage = () => {

    const data = useStaticQuery(graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
        allMdx(sort: { fields: [frontmatter___date], order: ASC }) {
          edges {
            node {
              excerpt
              fields {
                slug
              }
              body
              frontmatter {
                date(formatString: "MMMM DD, YYYY")
                artist_eng
                artist_chi
                slug
                imageUrl
                audioFile
              }
            }
          }
        }
      }
    `)


    const songs = data.allMdx.edges;

    // Set current artist
      const [currArtist, setCurrArtist] = useState({
        artist_id: 0,
        artist_eng: songs[0].node.frontmatter.artist_eng, 
        artist_chi: songs[0].node.frontmatter.artist_chi,
        imageUrl: songs[0].node.frontmatter.imageUrl, 
        audioFile: songs[0].node.frontmatter.audioFile, 
        bio: songs[0].node.body
      });


    // Show artist bio
      const [bioShowing, setBioShow] = useState(false);

      const showBioHandler = () => {

        if (bioShowing == false){
         
          setBioShow(true)
          //scroll to top
          window.scroll({
            top: 0, 
            left: 0, 
            behavior: 'smooth'
          });
        } else {
          setBioShow(false)
        }
        setAboutShow(false)
        setIntroShow(false)
      }

    // Show about content
      const [aboutShowing, setAboutShow] = useState(false)

      const showAboutHandler = () => {
        
          if (aboutShowing == false){
            
            setAboutShow(true)
            // scroll to top of the page; 
            window.scroll({
              top: 0, 
              left: 0, 
              behavior: 'smooth'
            });
          } else {
            setAboutShow(false)
          }
          setBioShow(false)
          setIntroShow(false)
      }

    // [CLICK] Intro handler
      const clickAboutHandler =() => {
        showAboutHandler();
      }

      // Show Intro content
      const [introShowing, setIntroShow] = useState(false)

      const showIntroHandler = () => {
        
          if (introShowing == false){
           
            setIntroShow(true)
            // scroll to top of the page; 
            window.scroll({
              top: 0, 
              left: 0, 
              behavior: 'smooth'
            });
          } else {
            setIntroShow(false)
          }
          setBioShow(false)
          setAboutShow(false)
      }

    // [CLICK] Intro handler
      const clickIntroHandler = () => {
        showIntroHandler();
      }

    // DATE GATE: can only play Lim + Alex's track until October 15th
    
    // Set hard launch date: October 15, 2020
      // const launchDate = new Date(2020, 9, 15, 0, 1, 1, 1);

    // test launch date
    const launchDate = new Date(2020, 7, 15, 0, 1, 1, 1)

    // check if launch date has passed 
      const hasLaunchPassed = () => {
        const todaysDate = new Date();
        if (launchDate.setHours(0,0,0,0) - todaysDate.setHours(0,0,0,0) >= 0 ){
          console.log("Has notlaunched!")
          // if has not launched yet, return false
          return false
        } else {
          console.log("has launched!")
          // if has launch day is here/has passed, return true
          return true
        }
      };


    // Check if song is Lim or Alex
      const isLimOrAlex = (artistIndex) => {
        // song is lim or alex
        if (artistIndex == 0 || artistIndex == 3) {
          // lim or alex; proceed
          return true
        } else {
          // artist is not lim or alex
          return false
        }
      };

    // Hard Launch alert text 
      const alertLaunchHandler = () => {
        console.log("in Alert Launch Handler")

        console.log( document.getElementById('launchAlert'))
        document.getElementById('launchAlert').classList.add(styles.showLaunchAlert); 
        setTimeout( () => {
          document.getElementById('launchAlert').classList.remove(styles.showLaunchAlert)
        }, 3000)
      };

    // Set music is playing/not playing
      const [isPlaying, setPlaying] = useState(false)

    // big play button 

    useEffect(()=> {
      bigPlay()
      
    }, [])
    
    let playBtnClasses= []; 
    
    if (isPlaying || !isLimOrAlex(currArtist.artist_id)){
      playBtnClasses =  [ styles.bigPlayButton, styles.hidePlay];
    } else if (!isPlaying && isLimOrAlex(currArtist.artist_id)) {
      playBtnClasses = [styles.bigPlayButton ]
    }

    const bigPlay = () => { 
      var playButton = document.getElementById("bigPlay")
      playButton.addEventListener("click", function(){
        resumeAudio(); 
        document.getElementById(`audio`).play();
        setPlaying(true);
      }) 
    };


    // [TOGGLE] play & or pause button
      const playPauseToggle = () => {
        if (hasLaunchPassed() || isLimOrAlex(currArtist.artist_id)) {
          if (!isPlaying ){
            resumeAudio();
            document.getElementById(`audio`).play();
            setPlaying(true)
          } else if (isPlaying){
            document.getElementById(`audio`).pause();
            setPlaying(false)
          }
        } else {
          document.getElementById(`audio`).pause();
          alertLaunchHandler()
          setPlaying(false);
        }
      }

    // Stop previous song, start next song
      const stopStartSong = () => {
        document.getElementById(`audio`).pause()
        setPlaying(false)
        resumeAudio();
        document.getElementById(`audio`).play()
        setPlaying(true)
      }

    // [CLICKED] song
      const clickSongHandler = (songIndex) => {

        console.log(songIndex)
        
        // if launch date has passed OR if clicked song is lim or alex
        if (hasLaunchPassed() || isLimOrAlex(songIndex)) {
          // proceed as usual
          setArtistHandler(songIndex);
          stopStartSong();
        } else  { 
          setArtistHandler(songIndex);
          alertLaunchHandler();
          setPlaying(false)
        }
      };


    // [CLICKED] artist
      const clickArtistHandler = (songIndex) => {
        setArtistHandler(songIndex);
        showBioHandler();
        if (hasLaunchPassed() || isLimOrAlex(songIndex)) {
          //proceed as usual
          stopStartSong();
        } else { // if launch date hasn't passed 
          //if song is not lim or alex and lau,
            alertLaunchHandler();
            // setPlaying to false
            setPlaying(false)
        }
      };

   
    // Set the current artist
      const setArtistHandler = (index) => {
        console.log("In Set Artist Handler")
        const artist = songs[index].node
        setCurrArtist ({
          artist_id: index, 
          artist_eng: artist.frontmatter.artist_eng, 
          artist_chi: artist.frontmatter.artist_chi,
          imageUrl: artist.frontmatter.imageUrl, 
          audioFile: artist.frontmatter.audioFile,
          bio: artist.body
        });
      }

    // Initialize Audio Contexts + Variables

    // audio data
    let dataArray; 

    // audio context

    // Default or backup webkit
    let context;

    // var AudioContext;

    // audio source
    let src; 
      
    // capture audio element
    let audio = useRef();


     
  // Recapture current audio (with new source) every time you set a current artist; 
    useEffect(()=> {
      var AudioContext = window.AudioContext || window.webkitAudioContext;
      // create a new audio context
      context = new AudioContext();
    }, [isPlaying])
  
    // initAudio every time src, context, audio changes after rerenders
    useEffect(()=>{
          initAudio()
    }, [src, context, audio])
    
    // delta values control impact/depth of seismic waves
    var deltas = {
      max: 0,
      applied: 0,
    };

    const resumeAudio = () => {
      context.resume().then(() => {
      });
    }
 
    // Initialize Audio Effects
    function initAudio () {
    
      // Provide the audio context with the current audio source
      src = context.createMediaElementSource(audio.current);
    
      // Create an analyser for the audio context
      const analyser = context.createAnalyser();

      // Connect audio context source to the analyser
      src.connect(analyser);

      // Provide destination for audio context (sends sound to speakers/headphones)
      analyser.connect(context.destination);

      // Average constant with the last analysis frame to make the transition between values over time smoother
      analyser.smoothingTimeConstant = 0.5;

      ////Analyser FFTSIZE ////
      analyser.fftSize = 4096; 

      const bufferLength = analyser.frequencyBinCount;

      //Read only property
      // Unsigned integer; half of fftSize (so in this case, bufferLength = 8192)
      // Equates to number of data values you have to play with for visualization
      //The FFT size defines the number of bines used for dividing the window into equal strips, or bins.
      //hence, a bin is a spectrum sample, and defines frequency resolution of the window.

      dataArray = new Uint8Array(bufferLength); 
      // Converts to an 8bit unsigned integer array 
      // at this point dataArray is an array with length of bufferlength but no values

      // Correlate the analyser data to render over time
      function renderFrame () {

          //takes callback function to invoke before rendering
          requestAnimationFrame(renderFrame);


          analyser.getByteFrequencyData(dataArray);
          // copies frequency data into dataArray 
          //results in a normalized array of values between 0 and 255
          // before this step, dataArray values are all zeros (but w length of 8192)
          
          for (let i = 0; i < 60; i++) {
              if (dataArray[i] > 150){ 
                deltas.max = 2.75
              } else if (dataArray[i] > 120){ 
                  deltas.max = 2
              } else if (dataArray[i] > 90){
                  deltas.max = 1.5
              } else if (dataArray[i] > 50){ 
                  deltas.max = 1
              } else { 
                  deltas.max = .25; 
              }
          };
      };
      renderFrame();  
      // end of initAudio
    };

    

    return (
       
        <div className={styles.wrapper}>
           <SEO
            title="A Pure Person"
            keywords= { ['pure person', 'lim giong', 'alex zhang hungtai', 'jieh', 'holodec', 'yuying', 'point', 'nonconfined space']}
          />
          <div className={styles.limguests_mobile} onClick={clickAboutHandler} tabIndex="0" > 
            <div> 林強 + 客人 </div>
            <div> Lim Giong &amp; Guests</div> 
          </div> 
          <ActionMenu
            click = {playPauseToggle}
            clickAbout = { clickAboutHandler }
            clickIntro = { clickIntroHandler }
            playing= { isPlaying && true }
          />
          <div className={styles.page_wrapper}>
            <header className={styles.site_id_wrapper}>
              <div className={styles.site_id_line}></div>
              <div className={styles.site_id_text}>
                <img className={styles.site_id_image} src="../pure_handwriting.svg"/>
                <div className={styles.site_id_title}> A Pure Person </div>
              </div>
            </header>
{/* 
            <AudioLogic
              isPlaying = { isPlaying }
              src = { src }
              context = { context }
              audio = { audio }
              deltas = { deltas }
              dataArray = { dataArray}
            ></AudioLogic> */}

            <ul className={styles.song_list_wrapper}>
              { songs.map(({ node }, index) => {
                if (index < 6 ) {
                  return(
                    <li key={`${node.frontmatter.slug}${index}`}>
                      <SongTitle
                        currArtistId = { currArtist.artist_id }
                        dataImageTag = {index}
                        clickSong = {clickSongHandler}
                        clickArtist = {clickArtistHandler}
                        index={ index}
                        name_eng = { node.frontmatter.artist_eng }
                        name_chi = { node.frontmatter.artist_chi && node.frontmatter.artist_chi }
                      />
                    </li>
                  )}
              })}
            </ul>

           

            <SongCanvas  
             
              playBtnClasses = {playBtnClasses}
              deltas = { deltas }
              clickSongHandler = { clickSongHandler}
              clickArtistHandler = {clickArtistHandler}
            >
              <div id="bigPlay" 
              className={playBtnClasses.join(' ')}
              aria-label="Click to pla" tabIndex="0"
              />
              <h4 role="status" tabIndex="-1" className={styles.launchDateAlert} id="launchAlert"> 
                A Pure Person Will Be Available October 15
              </h4>
              <audio 
                // controls
                src={currArtist.audioFile}
                ref={audio}
                id="audio" 
                >
                Your browser does not support the
                <code>audio</code> element.
              </audio>
            </SongCanvas>
              
            <Overlay 
              showOverlay = {bioShowing} 
              clicked={showBioHandler} 
              content={currArtist.bio}/>

            <AboutOverlay
              showAbout = { aboutShowing }
              clicked={ showAboutHandler }
            />

            <IntroOverlay 
              showIntro = {introShowing }
              clicked = { showIntroHandler }
            />
        
          </div>
          
        </div>        
    )
  };

export default IndexPage;