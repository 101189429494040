
import React from "react"
import styles from './actionMenu.module.scss'

const actionMenu = ({click, playing, clickAbout, clickIntro}) => {

    var playButton = (
      <img 
        alt="play"
        className={styles.audio_button}
        src = "../icon_play.svg" />
    )

    var pauseButton = (
      <img 
      alt="Pause"
        className={styles.audio_button}
        src = "../icon_pause.svg" />
    )

    const keyAbout = (e) => {
      if (e.keyCode === 13){
        clickAbout()
      }
    }
    
    return (
      
        <ul className={styles.action_menu} id="actionMenu">
          <li > 
            <button onClick={ clickIntro } role="link" className={styles.limguests_desktop} aria-label="Lim Giong &amp; Guests Introduction"> 林強 + 客人 <br/> Lim Giong &amp; Guests  </button>
          </li>
          <li> <a rel="preload" arial-label="Buy A Pure Person album" href="https://purepersonpress.bandcamp.com/"> Buy </a></li>
          <li> 
            <div aria-label="Learn more about the album" role="link" onClick={ clickAbout } onKeyUp = {keyAbout} tabIndex="0"> About </div>
          </li>
          <li><a rel="preload" aria-label="Contact A Pure Person Press" href="mailto:angella.lin@gmail.com"> Contact </a></li>
          <li >
            <button onClick={click} aria-label="Play or pause the music">{ playing ? pauseButton : playButton}</button>
          </li> 
        </ul>
    )
}

export default actionMenu; 

