import React, {useEffect } from "react"
import styles from "./songCanvas.module.scss"
import "../styles.scss" 
import { Curtains } from "curtainsjs";

import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image";

const SongCanvas = ({ children, deltas, clickSongHandler, clickArtistHandler}) => {

const data = useStaticQuery(graphql`
        query { 
            limImageWebP: file(relativePath: { eq: "lim.webp" }) {
                childImageSharp { 
                    fluid(maxWidth: 1080, quality: 100) { 
                        ...GatsbyImageSharpFluid_noBase64
                    }
                }
            }

            limImageJpg: file( relativePath: {eq: "lim.jpg"}) {
                childImageSharp{
                    fluid( maxWidth: 1080, quality: 100) {
                        ...GatsbyImageSharpFluid_noBase64
                    }
                }
            }

            pointImageWebP:file(relativePath: { eq: "point.webp" }) {
                childImageSharp { 
                    fluid(maxWidth: 1080, quality: 100) { 
                        ...GatsbyImageSharpFluid_noBase64
                    }
                }
            } 

            pointImageJpg:file(relativePath: { eq: "point.jpg" }) {
                childImageSharp { 
                    fluid(maxWidth: 1080, quality: 100) { 
                        ...GatsbyImageSharpFluid_noBase64
                    }
                }
            } 

            yyImageWebP:file(relativePath: { eq: "yuying.webp" }) {
                childImageSharp { 
                    fluid(maxWidth: 1080, quality: 100) { 
                        ...GatsbyImageSharpFluid_noBase64
                    }
                }
            } 

            yyImageJpg:file(relativePath: { eq: "yuying.jpg" }) {
                childImageSharp { 
                    fluid(maxWidth: 1080, quality: 100) { 
                        ...GatsbyImageSharpFluid_noBase64
                    }
                }
            } 

            alexImageWebP:file(relativePath: { eq: "alex.webp" }) {
                childImageSharp { 
                    fluid(maxWidth: 1080, quality: 100) { 
                        ...GatsbyImageSharpFluid_noBase64
                    }
                }
            } 

            alexImageJpg:file(relativePath: { eq: "alex.jpg" }) {
                childImageSharp { 
                    fluid(maxWidth: 1080, quality: 100) { 
                        ...GatsbyImageSharpFluid_noBase64
                    }
                }
            } 

            ncsImageWebP:file(relativePath: { eq: "nonconfined.webp" }){
                childImageSharp { 
                    fluid(maxWidth: 1080, quality: 100) { 
                        ...GatsbyImageSharpFluid_noBase64
                    }
                }
            } 

            ncsImageJpg:file(relativePath: { eq: "nonconfined.jpg" }){
                childImageSharp { 
                    fluid(maxWidth: 1080, quality: 100) { 
                        ...GatsbyImageSharpFluid_noBase64
                    }
                }
            } 

            jiehImageWebP:file(relativePath: { eq: "jieh.webp" }) {
                childImageSharp { 
                    fluid(maxWidth: 1080, quality: 100) { 
                        ...GatsbyImageSharpFluid_noBase64
                    }
                }
            } 

            jiehImageJpg:file(relativePath: { eq: "jieh.jpg" }) {
                childImageSharp { 
                    fluid(maxWidth: 1080, quality: 100) { 
                        ...GatsbyImageSharpFluid_noBase64
                    }
                }
            } 
        }
    `)

    console.log("songCanvas DATA")
    console.log(data)

    // Initialize Curtains once

    useEffect(()=> {
        initCurtains();
      },[])        
     
      // Initialize Curtains animation effects
      function initCurtains() {
    
        // Position of ripple impact (appropriated code used mouse movement)
        var mousePosition = {
            x: 0,
            y: 0,
        };
    
        // set up our WebGL context and append the canvas to our wrapper
        var webGLCurtain = new Curtains({
            container: "canvas",
            watchScroll: false // no need to listen for the scroll in this example
        });
    
        // Error handling
        webGLCurtain.onError(function() {
            // we will add a class to the document body to display original images
            document.body.classList.add("no-curtains");
        }).onContextLost(function() {
            // on context lost, try to restore the context
            webGLCurtain.restoreContext();
        });
    
        // Capture our plane element
        var planeElements = document.getElementsByClassName(styles.curtain)
  
        // Vertex shader
        var vs = `
            precision mediump float;
  
            // default mandatory variables
            attribute vec3 aVertexPosition;
            attribute vec2 aTextureCoord;
            uniform mat4 uMVMatrix;
            uniform mat4 uPMatrix;
            
            // our texture matrix uniform
            uniform mat4 simplePlaneTextureMatrix;
  
            // custom variables
            varying vec3 vVertexPosition;
            varying vec2 vTextureCoord;
            uniform float uTime;
            uniform vec2 uResolution;
            uniform vec2 uMousePosition;
            uniform float uMouseMoveStrength;
  
            void main() {
                vec3 vertexPosition = aVertexPosition;
                
                // get the distance between our vertex and the mouse position
                float distanceFromMouse = distance(uMousePosition, vec2(vertexPosition.x, vertexPosition.y));
            
                // calculate our wave effect
                float waveSinusoid = cos(5.0 * (distanceFromMouse - (uTime / 75.0)));
                
                // attenuate the effect based on mouse distance
                float distanceStrength = (0.4 / (distanceFromMouse + 0.4));
                
                // calculate our distortion effect
                float distortionEffect = distanceStrength * waveSinusoid * uMouseMoveStrength;
                
                // apply it to our vertex position
                vertexPosition.z +=  distortionEffect / 30.0;
                vertexPosition.x +=  (distortionEffect / 30.0 * (uResolution.x / uResolution.y) * (uMousePosition.x - vertexPosition.x));
                vertexPosition.y +=  distortionEffect / 30.0 * (uMousePosition.y - vertexPosition.y);
                gl_Position = uPMatrix * uMVMatrix * vec4(vertexPosition, 1.0);
                // varyings
                vTextureCoord = (simplePlaneTextureMatrix * vec4(aTextureCoord, 0.0, 1.0)).xy;
                vVertexPosition = vertexPosition;
            }
        `;
        
        // Fragment shader
        var fs = `
            precision mediump float;
            varying vec3 vVertexPosition;
            varying vec2 vTextureCoord;
            uniform sampler2D simplePlaneTexture;
            void main() {
                // apply our texture
                vec4 finalColor = texture2D(simplePlaneTexture, vTextureCoord);
  
                // fake shadows based on vertex position along Z axis
                finalColor.rgb -= clamp(-vVertexPosition.z, 0.0, 1.0);
  
                // fake lights based on vertex position along Z axis
                finalColor.rgb += clamp(vVertexPosition.z, 0.0, 1.0);
  
                // handling premultiplied alpha (useful if we were using a png with transparency)
                finalColor = vec4(finalColor.rgb * finalColor.a, finalColor.a);
                gl_FragColor = finalColor;
            }
        `;
    
        // Curtain / shader parameters
        var params = {
            vertexShader: vs,
            fragmentShader: fs,
            widthSegments: 20,
            heightSegments: 20,
            uniforms: {
                resolution: { // resolution of our plane
                    name: "uResolution",
                    type: "2f", // notice this is an length 2 array of floats
                    value: [planeElements[0].clientWidth, planeElements[0].clientHeight],
                },
                time: { // time uniform that will be updated at each draw call
                    name: "uTime",
                    type: "1f",
                    value: 0,
                },
                mousePosition: { // our mouse position
                    name: "uMousePosition",
                    type: "2f", // again an array of floats
                    value: [mousePosition.x, mousePosition.y],
                },
                mouseMoveStrength: { // the mouse move strength
                    name: "uMouseMoveStrength",
                    type: "1f",
                    value: 0,
                }
            }
        };
    
        // Create our plane
        var simplePlane = webGLCurtain.addPlane(planeElements[0], params);
    
        // Error handling: if there is an init error, simplePlane will be null
        simplePlane && simplePlane.onReady(function() {
  
            // set a fov of 35 to reduce perspective
            simplePlane.setPerspective(35);
  
            // Set the first image as first texture
            let simplePlaneTexture = simplePlane.createTexture({
              sampler: "simplePlaneTexture",
              fromTexture: simplePlane.textures[0]
            });
  
            // Capture and cycle through clickables  
            var planeButtons = document.getElementsByClassName("change-image");
  
            // addEventListener to change active textures
            for (var i = 0; i < planeButtons.length; i++ ){
                planeButtons[i].addEventListener("click", function(e){
            
                // When [CLICKED], switch active texture
                simplePlaneTexture.setSource(simplePlane.images[e.target.dataset.img])
               
                // When [CLICKED]
                if (e.target.dataset.clickedon === "song"){
                  // change song
                  clickSongHandler(e.target.dataset.songindex)
                } else {
                  // change song + show artist overlay
                  clickArtistHandler(e.target.dataset.songindex)
                }
              })

              planeButtons[i].addEventListener("keyup", function (e) {
              
                if (parseInt(e.keyCode) ===13) {
                    // When [CLICKED], switch active texture
                    simplePlaneTexture.setSource(simplePlane.images[e.target.dataset.img])
               
                    // When [CLICKED]
                    if (e.target.dataset.clickedon === "song"){
                    // change song
                    clickSongHandler(e.target.dataset.songindex)
                    } else {
                    // change song + show artist overlay
                    clickArtistHandler(e.target.dataset.songindex)
                    }
                }
                
              })
            }
        }).onRender(function() {
            // Increment our time uniform
            simplePlane.uniforms.time.value++;
    
            // Decrease both deltas by damping : if the user doesn't move the mouse, effect will fade away
            deltas.applied += (deltas.max - deltas.applied) * 0.02;
            deltas.max += (0 - deltas.max) * 0.01;
            deltas.max = .5;
    
            // Send the new mouse move strength value
            simplePlane.uniforms.mouseMoveStrength.value = deltas.applied;
  
        }).onAfterResize(function() {
          
            var planeBoundingRect = simplePlane.getBoundingRect();
            
            simplePlane.uniforms.resolution.value = [planeBoundingRect.width, planeBoundingRect.height];
        });
      }

    return (
        <div className={styles.image_wrap}>
            
              <div id="canvas"></div>
              <div 
                className={styles.curtain}
                id="curtain">
                {/* <Img id = "image_0" className = {styles.imageTextures} alt="" fluid={data.limImageWebP.childImageSharp.fluid} fadeIn={true}  loading="eager"/>
                <Img id = "image_1" className = {styles.imageTextures} alt="" fluid={data.pointImageWebP.childImageSharp.fluid} fadeIn={true}  loading="eager"/>
                <Img id = "image_2" className = {styles.imageTextures} alt="" fluid={data.yyImageWebP.childImageSharp.fluid} fadeIn={true}  loading="eager"/>
                <Img id = "image_3" className = {styles.imageTextures} alt="" fluid={data.alexImageWebP.childImageSharp.fluid} fadeIn={true}  loading="eager"/>
                <Img id = "image_4" className = {styles.imageTextures} alt="" fluid={data.ncsImageWebP.childImageSharp.fluid} fadeIn={true}  loading="eager"/>
                <Img id = "image_5" className = {styles.imageTextures} alt="" fluid={data.jiehImageWebP.childImageSharp.fluid} fadeIn={true}  loading="eager"/> */}

                <picture> 
                    <source src={data.limImageWebP.childImageSharp.fluid} type="image/webp"></source>
                    <Img id = "image_0" className = {styles.imageTextures} alt="" fluid={data.limImageJpg.childImageSharp.fluid} fadeIn={true}  loading="eager"/>
                </picture>

                <picture> 
                    <source src={data.pointImageWebP.childImageSharp.fluid} type="image/webp"></source>
                    <Img id = "image_1" className = {styles.imageTextures} alt="" fluid={data.pointImageJpg.childImageSharp.fluid} fadeIn={true}  loading="eager"/>
                </picture>

                <picture> 
                    <source src={data.yyImageWebP.childImageSharp.fluid} type="image/webp"></source>
                    <Img id = "image_2" className = {styles.imageTextures} alt="" fluid={data.yyImageJpg.childImageSharp.fluid} fadeIn={true}  loading="eager"/>
                </picture>

                <picture> 
                    <source src={data.alexImageWebP.childImageSharp.fluid} type="image/webp"></source>
                    <Img id = "image_3" className = {styles.imageTextures} alt="" fluid={data.alexImageJpg.childImageSharp.fluid} fadeIn={true}  loading="eager"/>
                </picture>

                <picture> 
                    <source src={data.ncsImageWebP.childImageSharp.fluid} type="image/webp"></source>
                    <Img id = "image_4" className = {styles.imageTextures} alt="" fluid={data.ncsImageJpg.childImageSharp.fluid} fadeIn={true}  loading="eager"/>
                </picture>

                <picture> 
                    <source src={data.jiehImageWebP.childImageSharp.fluid} type="image/webp"></source>
                    <Img id = "image_5" className = {styles.imageTextures} alt="" fluid={data.jiehImageJpg.childImageSharp.fluid} fadeIn={true}  loading="eager"/>
                </picture>

                  {children}
              </div>
            </div>
    )
}

export default SongCanvas; 

